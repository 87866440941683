import { EColor } from '@frontend/pole-ui/lib/common/constants'
import { EIconName } from '@frontend/pole-ui/lib/components/Icon'
import omit from 'lodash/omit'
import { messages } from './messages'
import { TFinancingOfferModalStepsAdapter } from './types'

export const defaultFinancingOfferStepsAdapter: TFinancingOfferModalStepsAdapter = (steps, { onFormInteraction }, onClickConsult) =>
  steps.map(step => ({
    ...step,
    fields: step.fields.map(field => {
      const controlledField = { ...field }

      if (field.fieldType === 'checkbox' || field.fieldType === 'policyAgreement')
        return omit(controlledField, ['activeControlStyle', 'borderColorStyle', 'markColor'])

      if (field.fieldType === 'infoText')
        return {
          ...field,
          className: 'loanProductOrderForm__infoText',
          iconProps: {
            name: EIconName.InfoFill,
            className: 'loanProductOrderForm__infoIcon',
            style: { color: EColor.GREEN },
          },
        }

      if (field.name === 'loanAmount.value') return { ...controlledField, shouldBeTouchedBeforeError: true }

      return controlledField
    }),
    calcButton: {
      modifiers: ['outline-gray', 'full-width-mobile'],
      textId: messages.actions.requestConsult,
      onClick: onClickConsult,
    },
    submitButton: {
      ...step.submitButton,
      modifiers: ['green', 'full-width-mobile'],
    },
  }))
