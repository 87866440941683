import { IS_ALFA_AND_STONE_XXI_LEASING_PRODUCTS_HIDDEN } from '@/constants/financing'
import { EFinancingOfferRoute, EFinancingProductId } from '@/types'
import { ILeasingOffer } from './types'

export const LEASING_OFFERS: Readonly<ILeasingOffer[]> = [
  {
    type: 'sberLeasing',
    productId: EFinancingProductId.SBER_LEASING,
    image: {
      source: 'sber-leasing',
      width: 240,
    },
    url: EFinancingOfferRoute.sberLeasing,
    benefits: ['sale'],
    detail: {
      value: 'fromFivePercent',
      sign: 'advancePayment',
    },
  },
  {
    type: 'stoneXxiLeasing',
    productId: EFinancingProductId.LEASING_STONE_XXI,
    image: {
      source: 'stone-xxi-leasing',
      width: 220,
    },
    url: EFinancingOfferRoute.stoneXxiLeasing,
    benefits: ['sale'],
    detail: {
      value: 'fromFivePercent',
      sign: 'advancePayment',
    },
    isHidden: IS_ALFA_AND_STONE_XXI_LEASING_PRODUCTS_HIDDEN,
  },
  {
    type: 'interleasingLeasing',
    productId: EFinancingProductId.LEASING_INTERLEASING,
    image: {
      source: 'interleasing-leasing',
      width: 220,
    },
    url: EFinancingOfferRoute.interleasing,
    benefits: ['sale'],
    detail: {
      value: 'fromTenPercent',
      sign: 'advancePayment',
    },
  },
  {
    type: 'alfaLeasing',
    productId: EFinancingProductId.LEASING_ALFA,
    image: {
      source: 'alfa-leasing',
      width: 220,
    },
    url: EFinancingOfferRoute.alfaLeasing,
    benefits: ['sale'],
    detail: {
      value: 'fromZeroPercent',
      sign: 'advancePayment',
    },
    isHidden: IS_ALFA_AND_STONE_XXI_LEASING_PRODUCTS_HIDDEN,
  },
  {
    type: 'baltiyskiyLeasing',
    productId: EFinancingProductId.LEASING_BALTIYSKIY,
    image: {
      source: 'baltiyskiy-leasing',
      width: 220,
    },
    url: EFinancingOfferRoute.baltiyskiyLeasing,
    benefits: ['sale'],
    detail: {
      value: 'fromZeroPercent',
      sign: 'advancePayment',
    },
    isHidden: true,
  },
  {
    type: 'vtbLeasing',
    productId: EFinancingProductId.LEASING_VTB,
    image: {
      source: 'vtb-leasing',
      width: 220,
    },
    url: EFinancingOfferRoute.transportAndSpecialEquipmentLeasing,
    benefits: ['sale'],
    detail: {
      value: 'fromSevenPercent',
      sign: 'advancePayment',
    },
    isHidden: true,
  },
  {
    type: 'rosAgroLeasing',
    productId: EFinancingProductId.LEASING_RAL,
    image: {
      source: 'rosagro-leasing',
      width: 220,
    },
    url: EFinancingOfferRoute.agriculturalMachinesAndEquipmentLeasing,
    benefits: ['sale'],
    detail: {
      value: 'fromZeroPercent',
      sign: 'advancePayment',
    },
  },
  {
    type: 'fleetFinanceLeasing',
    productId: EFinancingProductId.LEASING_FLEET_FINANCE,
    image: {
      source: 'fleet-finance-leasing',
      width: 220,
    },
    url: EFinancingOfferRoute.fleetFinanceLeasing,
    benefits: ['discount'],
    detail: {
      value: 'fromZeroPercent',
      sign: 'advancePayment',
    },
  },
]
