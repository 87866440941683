import { createContext } from 'react'
import { financingPageBreadcrumb, homeBreadcrumb } from '@/components/ui/Breadcrumbs/constants'
import { IProductsListContext } from '@/pages/Financing/types'
import { EFinancingOfferType } from '@/types'

export enum EFinancingCategory {
  LOANS = 'loans',
  LEASING = 'leasing',
}

export const FINANCING_CATEGORIES = [EFinancingCategory.LOANS, EFinancingCategory.LEASING]

export const FINANCING_BREADCRUMBS = [homeBreadcrumb, financingPageBreadcrumb]

export const OFFER_TYPES_WITH_DISABLED_FIELDS_WHEN_USER_AUTH = [EFinancingOfferType.CREDIT_TINKOFF]

export const ProductsListContext = createContext<IProductsListContext | null>(null)
