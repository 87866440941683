import React, { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { RANGES } from 'src/components/composed/LoanProductCalc/constants'
import { LoanProductCalcPs } from 'src/components/composed/LoanProductCalc/LoanProductCalcPs'
import { messages } from 'src/components/composed/LoanProductCalc/messages'
import { declension } from '@/components/composed/LoanProductCalc/helpers'
import { RangeSlider } from '@/components/ui/ReactHookFormFields'
import { EPriceCurrency } from '@/types'
import { useDeviceType } from '@/utils/hooks/useDeviceType'

export const LoanProductCalcForm: FC = () => {
  const { control } = useFormContext()
  const { formatNumber } = useIntl()
  const { isDesktop } = useDeviceType()

  const formatSum = (value: number) => `${formatNumber(value)} ${EPriceCurrency.RUB}`
  const formatDay = (value: number) => `${declension(Number(formatNumber(value)))} `

  return (
    <div className="loanProductCalcForm">
      <div className="loanProductCalcForm__fields">
        <RangeSlider
          control={control}
          label={messages.form.size}
          name="sum"
          sliderProps={RANGES.sum}
          formatValue={formatNumber}
          inputEnding={EPriceCurrency.RUB}
          formatLabel={formatSum}
        />

        <RangeSlider
          control={control}
          label={messages.form.term}
          name="days"
          sliderProps={RANGES.days}
          formatValue={formatNumber}
          formatLabel={formatDay}
        />
      </div>

      {isDesktop && <LoanProductCalcPs />}
    </div>
  )
}
