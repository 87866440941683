import { SyntheticEvent } from 'react'
import { pushFormattedGtmEvent } from '@/logic/metrika/helpers'
import { getStepsWithHandlers } from '@/pages/Financing/helpers'
import { EFinancingOfferType } from '@/types'
import { ICustomizedFormStepProps } from '../../forms/CustomizedForms/interfaces'
import { ApplyingOfferFormGtmFieldName, gtmChangeEventPayloads, gtmFocusEventPayloads } from './gtmConstants'
import { TFieldNames, THandlers } from './interfaces'

export type THandlerCreator = (fieldName: TFieldNames, isNotInputField?: boolean) => (event: SyntheticEvent) => void

export const useApplyingOfferFormSteps = (
  offerType: EFinancingOfferType,
  isAuthenticated: boolean | undefined,
): ICustomizedFormStepProps[] => {
  const createFocusEventHandler: THandlerCreator = fieldName => () => {
    const selectors = `[name=${fieldName.split('_')[0]?.replace('.', '\\.')}]`
    const field = document.querySelector<HTMLInputElement>(selectors)
    const { isTouched } = field?.dataset || {}

    if (!isTouched && gtmFocusEventPayloads[fieldName]) {
      pushFormattedGtmEvent(gtmFocusEventPayloads[fieldName]!)
      field?.setAttribute('data-is-touched', 'true')
    }
  }

  const createChangeEventHandler: THandlerCreator =
    (fieldName, isNotInputField) =>
    ({ type }: SyntheticEvent) => {
      const selectors = `[name=${fieldName.split('_')[0]?.replace('.', '\\.')}]`
      const field = document.querySelector<HTMLInputElement>(selectors)
      const { isChanged } = field?.dataset || {}

      /*проверка type === 'change' необходима потому, что onBlur в react-input-mask тригерит onChange
      https://github.com/sanniassin/react-input-mask/issues/111 */
      if (!isChanged && gtmChangeEventPayloads[fieldName] && (type === 'change' || isNotInputField)) {
        pushFormattedGtmEvent(gtmChangeEventPayloads[fieldName])
        field?.setAttribute('data-is-changed', 'true')
      }
    }

  const handlers: THandlers = {
    [ApplyingOfferFormGtmFieldName.loanAmountCredit]: [
      { handlerName: 'onFocus', handler: createFocusEventHandler(ApplyingOfferFormGtmFieldName.loanAmountCredit) },
      { handlerName: 'onChange', handler: createChangeEventHandler(ApplyingOfferFormGtmFieldName.loanAmountCredit) },
    ],
    [ApplyingOfferFormGtmFieldName.phoneCredit]: [
      { handlerName: 'onFocus', handler: createFocusEventHandler(ApplyingOfferFormGtmFieldName.phoneCredit) },
      { handlerName: 'onChange', handler: createChangeEventHandler(ApplyingOfferFormGtmFieldName.phoneCredit) },
    ],
    [ApplyingOfferFormGtmFieldName.cityCredit]: [
      { handlerName: 'onFocus', handler: createFocusEventHandler(ApplyingOfferFormGtmFieldName.cityCredit) },
      { handlerName: 'onChange', handler: createChangeEventHandler(ApplyingOfferFormGtmFieldName.cityCredit) },
    ],
    [ApplyingOfferFormGtmFieldName.commentCredit]: [
      { handlerName: 'onFocus', handler: createFocusEventHandler(ApplyingOfferFormGtmFieldName.commentCredit) },
      { handlerName: 'onChange', handler: createChangeEventHandler(ApplyingOfferFormGtmFieldName.commentCredit) },
    ],
    [ApplyingOfferFormGtmFieldName.phoneLeasingRal]: [
      { handlerName: 'onFocus', handler: createFocusEventHandler(ApplyingOfferFormGtmFieldName.phoneLeasingRal) },
      { handlerName: 'onChange', handler: createChangeEventHandler(ApplyingOfferFormGtmFieldName.phoneLeasingRal) },
    ],
    [ApplyingOfferFormGtmFieldName.commentLeasingRal]: [
      { handlerName: 'onFocus', handler: createFocusEventHandler(ApplyingOfferFormGtmFieldName.commentLeasingRal) },
      { handlerName: 'onChange', handler: createChangeEventHandler(ApplyingOfferFormGtmFieldName.commentLeasingRal) },
    ],
    [ApplyingOfferFormGtmFieldName.phoneAdvance]: [
      { handlerName: 'onChange', handler: createChangeEventHandler(ApplyingOfferFormGtmFieldName.phoneAdvance) },
    ],
    [ApplyingOfferFormGtmFieldName.contactPersonPositionAdvance]: [
      { handlerName: 'onChange', handler: createChangeEventHandler(ApplyingOfferFormGtmFieldName.contactPersonPositionAdvance) },
    ],
    [ApplyingOfferFormGtmFieldName.loanAmountAdvance]: [
      { handlerName: 'onChange', handler: createChangeEventHandler(ApplyingOfferFormGtmFieldName.loanAmountAdvance) },
    ],
    [ApplyingOfferFormGtmFieldName.cropsAreaAdvance]: [
      { handlerName: 'onChange', handler: createChangeEventHandler(ApplyingOfferFormGtmFieldName.cropsAreaAdvance) },
    ],
    [ApplyingOfferFormGtmFieldName.desiredAdvancePaymentDateAdvance]: [
      { handlerName: 'onSelect', handler: createChangeEventHandler(ApplyingOfferFormGtmFieldName.desiredAdvancePaymentDateAdvance, true) },
    ],
    [ApplyingOfferFormGtmFieldName.grossHarvestAdvance]: [
      { handlerName: 'onChange', handler: createChangeEventHandler(ApplyingOfferFormGtmFieldName.grossHarvestAdvance) },
    ],
    [ApplyingOfferFormGtmFieldName.commentAdvance]: [
      { handlerName: 'onChange', handler: createChangeEventHandler(ApplyingOfferFormGtmFieldName.commentAdvance) },
    ],
    [ApplyingOfferFormGtmFieldName.phoneLeasingVTB]: [
      { handlerName: 'onFocus', handler: createFocusEventHandler(ApplyingOfferFormGtmFieldName.phoneLeasingVTB) },
      { handlerName: 'onChange', handler: createChangeEventHandler(ApplyingOfferFormGtmFieldName.phoneLeasingVTB) },
    ],
    [ApplyingOfferFormGtmFieldName.emailLeasingVTB]: [
      { handlerName: 'onFocus', handler: createFocusEventHandler(ApplyingOfferFormGtmFieldName.emailLeasingVTB) },
      { handlerName: 'onChange', handler: createChangeEventHandler(ApplyingOfferFormGtmFieldName.emailLeasingVTB) },
    ],
    [ApplyingOfferFormGtmFieldName.agreementLeasingVTB]: [
      { handlerName: 'onChange', handler: createChangeEventHandler(ApplyingOfferFormGtmFieldName.agreementLeasingVTB) },
    ],
    [ApplyingOfferFormGtmFieldName.phoneTinkoff]: [
      { handlerName: 'onFocus', handler: createFocusEventHandler(ApplyingOfferFormGtmFieldName.phoneTinkoff) },
      { handlerName: 'onChange', handler: createChangeEventHandler(ApplyingOfferFormGtmFieldName.phoneTinkoff) },
    ],
    [ApplyingOfferFormGtmFieldName.fullNameTinkoff]: [
      { handlerName: 'onFocus', handler: createFocusEventHandler(ApplyingOfferFormGtmFieldName.fullNameTinkoff) },
      { handlerName: 'onChange', handler: createChangeEventHandler(ApplyingOfferFormGtmFieldName.fullNameTinkoff) },
    ],
    [ApplyingOfferFormGtmFieldName.organizationInnTinkoff]: [
      { handlerName: 'onFocus', handler: createFocusEventHandler(ApplyingOfferFormGtmFieldName.organizationInnTinkoff) },
      { handlerName: 'onChange', handler: createChangeEventHandler(ApplyingOfferFormGtmFieldName.organizationInnTinkoff) },
    ],
    [ApplyingOfferFormGtmFieldName.organizationNameTinkoff]: [
      { handlerName: 'onFocus', handler: createFocusEventHandler(ApplyingOfferFormGtmFieldName.organizationNameTinkoff) },
      { handlerName: 'onChange', handler: createChangeEventHandler(ApplyingOfferFormGtmFieldName.organizationNameTinkoff) },
    ],
    [ApplyingOfferFormGtmFieldName.loanAmountTinkoff]: [
      { handlerName: 'onFocus', handler: createFocusEventHandler(ApplyingOfferFormGtmFieldName.loanAmountTinkoff) },
      { handlerName: 'onChange', handler: createChangeEventHandler(ApplyingOfferFormGtmFieldName.loanAmountTinkoff) },
    ],
  }

  return getStepsWithHandlers({ offerType, isAuthenticated, handlers })
}
