import { TMessages } from '@/types/TMessages'

export const messages = {
  title: 'Выберите опции',
  labels: {
    REPAYMENT_AT_THE_END: 'Погашение в конце срока',
    UNSECURED: 'Без залога земли или техники',
    PREFERENTIAL_RATES: 'Доступны льготные ставки',
  },
} satisfies TMessages
