import React from 'react'
import { Icon } from '@frontend/pole-ui/lib/components/Icon'
import { ITabContentLabelComponent } from '@/components/ui/Tabs/interfaces'
import { IWithFinancingProductsTabProps } from './types'
import './styles.scss'

export const WithFinancingProductsTab =
  ({ iconName, text }: IWithFinancingProductsTabProps): ITabContentLabelComponent =>
  () => {
    return (
      <span className="financingProductsTab">
        <Icon name={iconName} />
        <span className="financingProductsTab__text">{text}</span>
      </span>
    )
  }
