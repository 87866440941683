import { TMessages } from '@/types/TMessages'

export const messages = {
  discount5Pct: 'Скидка 5% на поле.рф',
  highApprovalRate: 'Высокий процент одобрения',
  threeMinutes: 'Деньги на счете за 3 минуты',
  industrySolution: 'Отраслевое решение',
  discountedRates: 'Доступны льготные ставки',
  smallestOverpayment: 'Наименьшая переплата',
} satisfies TMessages
