import { TMessages } from '@/types/TMessages'

export const messages = {
  title: 'Часто задаваемые вопросы',
  item1: {
    question: 'Какие требования к заемщику?',
    answer: {
      p: 'Минимальные усредненные требования для получения продуктов финансирования:',
      li1: 'Резидент РФ',
      li2: 'Срок ведения бизнеса не менее 6-ти месяцев; или не менее 1-ого месяца – только для продуктов СберЛизинга (при наличии выручки)',
      li3: 'Отсутствие задолженности по налогам на сумму более 50 000 рублей',
      li4: 'Доля собственного капитала в структуре активов компании не менее 15% (для ООО)',
    },
  },
  item2: {
    question: 'Как снизить риск отказа по финансированию?',
    answer: {
      p: 'Перед подачей заявки на финансирование стоит рассмотреть:',
      li1: 'Погасить задолженности по кредитам/лизингам просроченные более чем на 30 дней',
      li2: 'Погасить неоплаченные налоги и сборы',
      li3: 'Выбрать юридическое лицо для подачи заявки (ИП / (Г) КФХ, ООО и т.д.), у которого наименьшая кредитная нагрузка',
      li4: 'Подготовить для сделки ликвидный залог. Не подойдут: земля арендованная менее чем на 25 лет, старая техника возрастом более 7-10 лет или не соответствующая по стоимости запрашиваемой сумме',
      li5: 'Выбрать юридическое лицо для подачи заявки (ИП / (Г) КФХ, ООО и т.д.) со сроком существования более 6 мес.',
      li6: 'Создать положительную кредитную историю за счет беспроцентного периода. При запросе суммы около 10 млн руб и более, при отсутствии положительной кредитной истории у вашего юридического лица – предварительно можно оформить, например, рассрочку или кредитную бизнес-карту с беспроцентным периодом. На данные средства совершить покупку и погасить задолженность в течение беспроцентного периода (минимум за месяц до подачи заявки), без уплаты процентов, комиссий и тд',
      li7: 'За альтернативными случаями обратиться за бесплатной консультацией к финансовому менеджеру поле.рф',
    },
  },
  item3: {
    question: 'Какой документооборот по сделкам в рассрочку на поле.рф?',
    answer: {
      p1: {
        anchor: 'Рассрочка с погашением в конце срока',
        text: ' от партнера поле.рф – МКК Агроинтегратор:',
      },
      li1: 'Анкета заемщика (короткая анкета, заполняется по шаблону)',
      li2: 'Финансовая отчётность за последние 2 отчетных периода (Ф1 и Ф2)',
      li3: 'Форма №29-СХ «Сведения о сборе урожая с/х культур либо Форма №1-КФХ «Информация о производственной деятельности КФХ» за последний отчетный период',
      li4: 'Форма №4-СХ «Сведения о итогах сева под урожай» за текущий период',
      li5: 'Копия удостоверения личности и Согласие на обработку персональных данных Заемщика, Залогодателя',
      li6: 'Документы на залог (с/х земля или техника)',
      p2: 'Рассрочка онлайн без залога от Сбера:',
      li7: 'Предоставление дополнительных документов не требуется – сделка проходит дистанционно через личный кабинет Сбера',
      li8: 'Однако если ваша организация – ООО с несколькими учредителями, то нужно будет подписать через мобильное приложение Сбера Согласие на обработку данных',
    },
  },
} satisfies TMessages
