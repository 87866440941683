import React, { FC, memo } from 'react'
import { useSelector } from 'react-redux'
import { isAuthenticated as isUserAuthenticated, isUserAgrarian } from '@/logic/auth/reducer'
import { RootState } from '@/redux/interfaces'
import { LeasingOffer, MultipleLeasing } from './components'
import { LEASING_OFFERS } from './constants'
import './styles.scss'

const mapState = (state: RootState) => ({
  isAgrarian: isUserAgrarian()(state),
  isAuthenticated: isUserAuthenticated()(state),
})

const LeasingOffers: FC = () => {
  const { isAgrarian, isAuthenticated } = useSelector(mapState)

  const shouldDisableButton = isAuthenticated && !isAgrarian

  return (
    <section className="leasingOffers">
      <div className="leasingOffers__list">
        {LEASING_OFFERS.map(offer => (
          <LeasingOffer offer={offer} shouldDisableButton={shouldDisableButton} key={offer.type} />
        ))}

        <MultipleLeasing />
      </div>
    </section>
  )
}

export default memo(LeasingOffers)
