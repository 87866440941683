import { TMessages } from '@/types/TMessages'

export const messages = {
  title: 'Отзывы о нас',
  reviews: [
    {
      name: 'Наталья, бухгалтер ООО «Промхим»',
      text: 'Приобретали дизельное топливо в рассрочку через поле.рф. Оперативно получили предложения от нескольких поставщиков и выбрали наиболее выгодное. Рассрочку одобрили и получили средства на оплату заказа в течение всего нескольких часов. Сделку полностью «под ключ» получилось провести меньше чем за 1 рабочий день.',
      location: 'Удмуртская Республика',
    },
    {
      name: 'ГКФХ Борисков Виктор Васильевич',
      text: 'Приобретали селитру и семена в рассрочку через поле.рф. Сделку от и до сопровождал личный менеджер от поле.рф. Оперативно помогли зарегистрировать землю в Росреестре для официального оформления в залог - в течение трёх дней получил деньги и оплатил заказ. Менеджер контролировал сроки и условия отгрузки товара поставщиком. Спасибо, продолжаем работать, с вами меньше головной боли!',
      location: 'Саратовская область',
      farm: ' 1 500 Га',
    },
    {
      name: 'Мурат Даурбеков',
      text: 'Сотрудничеством по удобрениям я доволен. Так и говорю знакомым аграриям: «Берите компьютер, регистрируйтесь, это не сложно — увидите, там можно покупать».',
      location: 'курская область',
      farm: ' 380 Га',
    },
    {
      name: 'Илья Трофимов',
      text: 'Очень хорошо поставленный рабочий процесс, коммуникабельные сотрудники, оперативное реагирование на запросы.',
      location: 'ставропольский край',
      farm: ' 4 300 Га',
    },
    {
      name: 'Владислав Кладиев',
      text: 'Команда поле.рф сопровождала сделку полностью от начала до конца. Могу дать положительную оценку работе платформы. Все вопросы решались оперативно.',
      location: 'Ростовская область',
      farm: 'Нефтебаза',
    },
    {
      name: 'Александр Березнев',
      text: 'Принимали участие в совместной закупке семян подсолнечника. Это было быстро и удобно, вопросов не возникло, всё устраивает более чем.',
      location: 'Воронежская область',
      farm: 'ООО «Агроколос»',
    },
    {
      name: 'Пётр Писклюков',
      text: 'Взаимодействие с платформой понравилось, трудностей не возникло, всё было понятно. Общение с менеджером — это очень удобно: можно что-то спросить или уточнить.',
      location: 'Воронежская область',
      farm: ' 500 га',
    },
    {
      name: 'Василий Еманов',
      text: 'Удобно, быстро, ещё и в кредит можно купить. Всё понравилось, всегда интересно новое. Мы планируем все заявки прогонять через поле.рф, мы за сотрудничество.',
      location: 'Волгоградская область',
      farm: ' 43 400 га',
    },
  ],
} satisfies TMessages
