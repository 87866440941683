import { EIconName } from '@frontend/pole-ui/lib/components/Icon'
import { WithFinancingProductsTab } from './components'
import { messages } from './messages'

export const LoanLabelComponent = WithFinancingProductsTab({
  iconName: EIconName.Sell,
  text: messages.tabs.loans,
})

export const LeasingLabelComponent = WithFinancingProductsTab({
  iconName: EIconName.Delivery,
  text: messages.tabs.leasing,
})
