import React, { FC, memo } from 'react'
import { EUserRegionSelectVariation, UserRegionSelect } from '@/components/ui/UserRegionSelect'
import { useGtm } from '@/logic/metrika/financing/gtmHelpers'
import { EGtmLabel } from '@/logic/metrika/financing/types'
import { messages } from './messages'
import './styles.scss'

const LoanProductsHeader: FC = () => {
  const { pushGtmFilter } = useGtm()

  const onClickPicker = () => pushGtmFilter(EGtmLabel.REGION_SELECTOR)

  return (
    <UserRegionSelect
      className="h3 h4-sm loanProductsHeader"
      picker={{
        desktop: EUserRegionSelectVariation.DESKTOP,
        tablet: EUserRegionSelectVariation.DESKTOP,
        mobile: EUserRegionSelectVariation.MOBILE,
      }}
      confirm={false}
      selector={{
        desktop: EUserRegionSelectVariation.DESKTOP,
        tablet: EUserRegionSelectVariation.MOBILE,
        mobile: EUserRegionSelectVariation.MOBILE,
      }}
      label={messages.title}
      onClickPicker={onClickPicker}
      showSelector
      genitiveCase
    />
  )
}

export default memo(LoanProductsHeader)
