import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import { Badge } from '@/components/composed/financing'
import { Button, Image } from '@/components/ui'
import { FORM_ANCHOR } from '@/constants/financing'
import { pushGtmEventOnRouteProductPage } from '@/logic/metrika/financing/gtmHelpers'
import { getUserGtmInfo } from '@/logic/metrika/helpers'
import { getUserRegion } from '@/logic/userRegion'
import { RootState } from '@/redux/interfaces'
import { useWebpExtension } from '@/utils/hooks/image'
import { ILeasingOfferProps } from './types'
import './styles.scss'

const mapState = (state: RootState) => ({
  userGtmInfo: getUserGtmInfo()(state),
  userRegion: getUserRegion()(state),
})

export const LeasingOffer: FC<ILeasingOfferProps> = props => {
  const { offer, shouldDisableButton } = props

  const { formatMessage } = useIntl()
  const { type, productId, image, url, benefits, detail, isHidden } = offer
  const { userGtmInfo, userRegion } = useSelector(mapState)
  const imageExtension = useWebpExtension()

  const detailLabel = formatMessage({ id: `financingOffer.detail.sign.${type}.${detail.sign}.capital` })
  const detailValue = formatMessage({ id: `financingOffer.detail.value.${type}.${detail.value}` })

  if (isHidden) return null

  return (
    <div className="leasingOffer elevatableBlock">
      <CommonLink
        className="leasingOffer__icon"
        url={url}
        onClick={() => pushGtmEventOnRouteProductPage(userGtmInfo, userRegion.name, 'financing-detail_logo-logo', productId)}
      >
        <Badge key={detail.sign} className="leasingOffer__detail" text={`${detailLabel} ${detailValue}`} />
        <div className="leasingOffer__imageWrapper">
          <Image
            className="leasingOffer__image"
            style={{ width: image.width }}
            src={`index/partners/${image.source}.${imageExtension}`}
            isInStatic
          />
        </div>
      </CommonLink>

      <div className="leasingOffer__benefit">
        {benefits.map(benefit => (
          <div key={benefit}>{formatMessage({ id: `financingOffer.benefit.${type}.${benefit}` })}</div>
        ))}
      </div>

      <CommonLink
        className="leasingOffer__link"
        url={`${url}${FORM_ANCHOR}`}
        onClick={() => pushGtmEventOnRouteProductPage(userGtmInfo, userRegion.name, 'financing-application-actions_button', productId)}
      >
        <Button className="leasingOffer__button" modifiers={['green', 'full-width-mobile']} isDisabled={shouldDisableButton} isFake>
          {formatMessage({ id: 'financingOffer.selectRequest' })}
        </Button>
      </CommonLink>
    </div>
  )
}
