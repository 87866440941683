import React, { FC, memo } from 'react'
import { Reviews } from '@/components/composed'
import { useGtm } from '@/logic/metrika/financing/gtmHelpers'
import { messages } from './messages'
import './styles.scss'

const FinancingResponses: FC = () => {
  const { pushGtmReviewsEvent } = useGtm()

  return (
    <Reviews
      title={messages.title}
      reviews={messages.reviews}
      className="financingReviews"
      titleClassName="financingReviews__title"
      sliderClassName="financingRReviews__slider"
      slideClassName="financingReviews__slide"
      onEvent={pushGtmReviewsEvent}
    />
  )
}

export default memo(FinancingResponses)
