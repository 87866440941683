import { EFinancingOfferType } from '@/types'
import { advancePaymentOfferModalStepsAdapter } from './helpers'
import { TFinancingOfferModalStepsAdapter } from './types'

export const FINANCING_OFFER_MODAL_STEPS_ADAPTER: Readonly<Partial<Record<EFinancingOfferType, TFinancingOfferModalStepsAdapter>>> = {
  [EFinancingOfferType.ADVANCE_PAYMENT]: advancePaymentOfferModalStepsAdapter,
}

export const FINANCING_OFFER_MODAL_CONTAINER_CLASSNAME_BY_OFFER_TYPE: Readonly<Partial<Record<EFinancingOfferType, string>>> = {
  [EFinancingOfferType.ADVANCE_PAYMENT]: 'financingOfferModal__container--advancePayment',
}
