import React, { FC } from 'react'
import { messages } from 'src/components/composed/LoanProductCalc/messages'

export const LoanProductCalcPs: FC = () => {
  return (
    <div className="loanProductCalcPs">
      <div>{messages.psSymbol}</div>
      <div>{messages.ps}</div>
    </div>
  )
}
