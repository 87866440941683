import React, { FC, useEffect, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import debounce from 'lodash/debounce'
import { Cell, Pie, PieChart, Sector } from 'recharts'
import { PieSectorDataItem } from 'recharts/types/polar/Pie'
import { ActiveShape } from 'recharts/types/util/types'
import { CalculateMutationRequest, useCalculate } from '@/api/kubik/credit'
import { PriceInfo } from '@/components/composed'
import { Button, GlassMorphIcon } from '@/components/ui'
import { EPriceUom } from '@/types'
import { formatLocalizedString } from '@/utils/formatUtils'
import { formatMoney, getPriceUom } from '@/utils/price'
import { COLORS, DEBOUNCE_DELAY, MAX_LOAN_AMOUNT, MIN_LOAN_AMOUNT } from './constants'
import { getErrorMessageBySumValue } from './helpers'
import { messages } from './messages'
import { ILoanProductCalcResult } from './types'

export const LoanProductCalcResult: FC<ILoanProductCalcResult> = props => {
  const { actionOnConsult, actionOnOffer } = props

  const { watch } = useFormContext()
  const { data, mutateAsync: calculate, isError } = useCalculate()

  const intl = useIntl()

  const [sumValue, daysValue] = watch(['sum', 'days'])

  const debouncedRequest = useMemo(
    () =>
      debounce(async (payload: CalculateMutationRequest) => {
        await calculate({ ...payload })
      }, DEBOUNCE_DELAY),
    [calculate],
  )

  const diagramData = useMemo(
    () => [
      {
        name: messages.result.sum,
        value: data?.data.data?.expressMfoTotalAmount,
      },
      { name: messages.result.percent, value: data?.data.data?.expressMfoPercentAmount },
    ],
    [data?.data.data?.expressMfoPercentAmount, data?.data.data?.expressMfoTotalAmount],
  )

  useEffect(() => {
    debouncedRequest({ expressMfoLoanTerm: daysValue, expressMfoAmount: sumValue })
  }, [daysValue, debouncedRequest, sumValue])

  const activeShape: ActiveShape<PieSectorDataItem> = (pipeProps: PieSectorDataItem) => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = pipeProps

    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          <tspan x={cx} dy="0" className="loanProductCalcResultDiagram__amountRefundedLabel">
            {messages.result.amountRefunded}
          </tspan>
          <tspan x={cx} dy="20">
            {formatMoney(Number(data?.data.data?.expressMfoTotalAmount.toFixed(0)), intl)}
            &nbsp;
            {getPriceUom(EPriceUom.RUB)}
          </tspan>
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </g>
    )
  }

  return (
    <div className="loanProductCalcResult">
      {isError ? (
        <div className="loanProductCalcResultError">
          <GlassMorphIcon image="attention-green" />

          <div className="loanProductCalcResultError__message">
            {formatLocalizedString(getErrorMessageBySumValue(sumValue), {
              minLoanAmount: formatMoney(MIN_LOAN_AMOUNT, intl),
              maxLoanAmount: formatMoney(MAX_LOAN_AMOUNT, intl),
            })}
          </div>
        </div>
      ) : (
        <>
          <div className="loanProductCalcResultWrapper">
            <div className="loanProductCalcResultValue">
              <h5 className="loanProductCalcResultValue__title">{messages.result.title}</h5>

              <div className="loanProductCalcResultValueItem loanProductCalcResultValueItem--amout">
                <div>{messages.result.sum}</div>
                <div className="loanProductCalcResultValueItem__data">
                  <PriceInfo price={{ value: Number(data?.data.data?.expressMfoAmount.toFixed(0)) || 0, uom: EPriceUom.RUB }} />
                </div>
              </div>

              <div className="loanProductCalcResultValueItem loanProductCalcResultValueItem--percent">
                <div>{messages.result.percent}</div>
                <div className="loanProductCalcResultValueItem__data">
                  <PriceInfo price={{ value: Number(data?.data.data?.expressMfoPercentAmount.toFixed(0)) || 0, uom: EPriceUom.RUB }} />
                </div>
              </div>
            </div>
            <div className="loanProductCalcResultDiagram">
              <PieChart width={200} height={200} className="loanProductCalcResultDiagram__pie">
                <Pie
                  data={diagramData}
                  innerRadius={80}
                  outerRadius={100}
                  dataKey="value"
                  activeIndex={0}
                  activeShape={activeShape}
                  stroke="none"
                >
                  {diagramData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
              </PieChart>
            </div>
          </div>
          <div className="loanProductCalcResultActions">
            <Button onClick={actionOnOffer} modifiers={['green', 'full-width-always']}>
              {messages.result.actions.sentOrder}
            </Button>
            <Button onClick={actionOnConsult} modifiers={['full-width-always', 'outline']}>
              {messages.result.actions.requestConsult}
            </Button>
          </div>
        </>
      )}
    </div>
  )
}
