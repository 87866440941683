import React, { FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { INITIAL_FORM_VALUES } from 'src/components/composed/LoanProductCalc/constants'
import { LoanProductCalcForm } from 'src/components/composed/LoanProductCalc/LoanProductCalcForm'
import { LoanProductCalcPs } from 'src/components/composed/LoanProductCalc/LoanProductCalcPs'
import { LoanProductCalcResult } from 'src/components/composed/LoanProductCalc/LoanProductCalcResult'
import { messages } from 'src/components/composed/LoanProductCalc/messages'
import { ILoanProductCalcProps } from 'src/components/composed/LoanProductCalc/types'
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import 'src/components/composed/LoanProductCalc/styles.scss'

export const LoanProductCalc: FC<ILoanProductCalcProps> = props => {
  const { isShowTitle = true, actionOnOffer, actionOnConsult } = props

  const { ...formMethod } = useForm({ defaultValues: INITIAL_FORM_VALUES })
  const { isMobile, isTablet } = useDeviceType()

  return (
    <div className="loanProductCalc">
      {isShowTitle && <h3 className="loanProductCalc__title">{messages.title}</h3>}

      <div className="loanProductCalcWrap">
        <FormProvider {...formMethod}>
          <LoanProductCalcForm />
          <LoanProductCalcResult actionOnOffer={actionOnOffer} actionOnConsult={actionOnConsult} />
          {(isMobile || isTablet) && <LoanProductCalcPs />}
        </FormProvider>
      </div>
    </div>
  )
}
