import React, { FC, memo } from 'react'
import { Button } from '@/components/ui'
import { useGtm } from '@/logic/metrika/financing/gtmHelpers'
import { EGtmLabel, EGtmProductName } from '@/logic/metrika/financing/types'
import { scrollToTop } from '@/utils/scroll'
import { messages } from './messages'
import './styles.scss'

const FinancingQuestions: FC = () => {
  const { pushGtmProduct } = useGtm()

  const onClick = () => {
    pushGtmProduct(EGtmProductName.MARKETPLACE, EGtmLabel.ACTION)
    scrollToTop()
  }

  return (
    <div className="financingQuestions">
      <h3 className="financingQuestions__title">{messages.title}</h3>
      <div className="financingQuestions__description">{messages.description}</div>
      <div className="financingQuestions__button">
        <Button modifiers={['green', 'full-width-mobile']} onClick={onClick}>
          {messages.button}
        </Button>
      </div>
    </div>
  )
}

export default memo(FinancingQuestions)
